export const environment = {
  production: true,
  backendUrl: 'https://prod-api.excelway.co/object',
  realtimeUrl: 'https://prod-realtime.excelway.co',
  attachmentUrl: 'https://prod-api.excelway.co/attachment',
  loginUrl: 'https://selfservice.excelway.co/#/login',
  kratosUrl: 'https://prod-kratos.excelway.co',
  appBaseUrl: 'https://app-new.excelway.co',
  verificationUrl: 'https://selfservice.excelway.co/#/email-verification',
  selfserviceUrl: 'https://selfservice.excelway.co/',
  kratosUpdaterUrl: 'https://prod-api.excelway.co/kratos-updater',
  completeRegistrationUrl:
    'https://selfservice.excelway.co/#/complete-registration',
};
