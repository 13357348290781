import { Route } from '@angular/router';

import { AuthGuard } from '@excelway/guards/auth.guard';
import { InitialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'app/layout/layout.component';

export const appRoutes: Route[] = [
  // Redirect empty path to '/home'
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('app/modules/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'mytasks',
        loadChildren: () =>
          import('app/modules/my-tasks/my-tasks.module').then(
            m => m.MyTasksModule
          ),
      },
      {
        path: 'workshops',
        loadChildren: () =>
          import('app/modules/workshops/workshops.module').then(
            m => m.WorkshopsModule
          ),
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('app/modules/projects/projects.module').then(
            m => m.ProjectsModule
          ),
      },
      {
        path: 'scrumboard',
        loadChildren: () =>
          import('app/modules/scrumboard/scrumboard.module').then(
            m => m.ScrumboardModule
          ),
      },
      {
        path: 'projects/project/:projectId/workshops',
        loadChildren: () =>
          import('app/modules/workshops/workshops.module').then(
            m => m.WorkshopsModule
          ),
      },
      {
        path: 'projects/project/:projectId/boards',
        loadChildren: () =>
          import('app/modules/boards/boards.module').then(m => m.BoardsModule),
      },
      {
        path: 'projects/project/:projectId/project-overview',
        loadChildren: () =>
          import(
            'app/modules/projects/project-overview/project-overview.module'
          ).then(m => m.ProjectOverviewModule),
      },
      {
        path: 'projects/project/:projectId/documents',
        loadChildren: () =>
          import('app/modules/documents/documents.module').then(
            m => m.DocumentsModule
          ),
      },
      {
        path: 'private-workshop',
        loadChildren: () =>
          import(
            'app/modules/admin/workshops/close-private-workshop/close-private-workshop.module'
          ).then(m => m.ClosePrivateWorkshopModule),
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('app/modules/admin-console/admin-console.module').then(
            m => m.AdminModule
          ),
      },
      {
        path: 'user-interface',
        loadChildren: () =>
          import('app/modules/user-interface/user-interface.module').then(
            m => m.UserInterfaceModule
          ),
      },
    ],
  },
];
