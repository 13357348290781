import { ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';

import { FuseModule } from '@fuse';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { FuseConfigModule } from '@fuse/services/config';

import { MatTabsModule } from '@angular/material/tabs';
import { CreateObjectModule } from '@excelway/components/modals/create-object/create-object.module';
import { GlobalErrorHandler } from '@excelway/services/error-interceptor.service';
import { RollbarErrorHandler } from '@excelway/services/rollbar-error-handler-service';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { mockApiServices } from 'app/mock-api';
import { environment } from 'environments/environment';
import * as Rollbar from 'rollbar';
import { NotificationsStore } from './layout/common/notifications/notifications.store';
import { CardStore } from './modules/scrumboard/card/card.store';
import { UserInterfaceModule } from './modules/user-interface/user-interface.module';
import { RootStoreModule } from './store/root-store.module';

const socketIoConfig: SocketIoConfig = {
  url: environment.realtimeUrl,
  options: {
    withCredentials: true,
  },
};

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
  useHash: true,
};

export function rollbarFactory(): Rollbar {
  const rollbarConfig = {
    accessToken: environment.production
      ? '8ed21db268834536bb4dd1c18fd2e0e3'
      : '',
    captureUncaught: true,
    captureUnhandledRejections: true,
  };
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    CreateObjectModule,
    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,
    MatMenuModule,
    MatTreeModule,
    MatIconModule,
    MatTabsModule,

    RootStoreModule,

    SocketIoModule.forRoot(socketIoConfig),
    UserInterfaceModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    CardStore,
    NotificationsStore,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    environment.production
      ? [
          { provide: ErrorHandler, useClass: RollbarErrorHandler },
          { provide: RollbarService, useFactory: rollbarFactory },
        ]
      : [
          { provide: ErrorHandler, useClass: RollbarErrorHandler },
          { provide: RollbarService, useFactory: rollbarFactory },
        ],
  ],
  exports: [],
})
export class AppModule {}
