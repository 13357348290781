import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateResponse } from '@excelway/models/api/create-response';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class MyNotificationsService {
  constructor(
    private _httpClient: HttpClient,
    private _apiService: ApiService
  ) {}

  getListOfNotifications<T>(userId: string): Observable<T> {
    return this._httpClient.get<T>(
      `${environment.backendUrl}/notification/${userId}`,
      {
        withCredentials: true,
      }
    );
  }

  createNotification(
    notifiedUsers: {
      id?: string;
      email: string;
      name?: string;
      redirectTo?: string;
      isRead?: boolean;
    }[],
    operationId: string,
    name: string,
    type: string,
    notificationSourceObject: { id: string; roleTypeName: string }
  ): Observable<CreateResponse> {
    const payload = {
      name,
      value: {
        type,
        notifiedUsers: notifiedUsers,
        notificationSourceObject: notificationSourceObject,
      },
    };

    return this._httpClient.post<CreateResponse>(
      `${environment.backendUrl}/notification/${operationId}`,
      payload,
      {
        withCredentials: true,
      }
    );
  }

  markAsRead(
    value: { isRead: boolean },
    notificationsIds: { id: string }[]
  ): Observable<any> {
    const payload = {
      value,
      notificationsIds,
    };

    return this._httpClient.put<CreateResponse>(
      `${environment.backendUrl}/notification/update`,
      payload,
      {
        withCredentials: true,
      }
    );
  }
}
