import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NotificationsStore } from 'app/layout/common/notifications/notifications.store';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private _httpClient: HttpClient,
    private toastr: ToastrService,
    private notificationsStore: NotificationsStore
  ) {}

  // Add member
  addMember(
    memberdata: any,
    roleTypeObjectId: string,
    roleTypeObject: string
  ): Observable<any> {
    return this._httpClient.patch(
      `${environment.backendUrl}/v1/${roleTypeObject}/${roleTypeObjectId}/add_member`,
      memberdata,
      { withCredentials: true }
    );
  }

  // update member
  updateMember(
    memberdata: any,
    roleTypeObjectId,
    roleTypeObject: string
  ): Observable<any> {
    return this._httpClient.patch(
      `${environment.backendUrl}/v1/${roleTypeObject}/${roleTypeObjectId}/edit_member`,
      memberdata,
      { withCredentials: true }
    );
  }

  // remove member
  removeMember(
    memberdata: any,
    roleTypeObjectId: string,
    roleTypeObject: string
  ): Observable<any> {
    return this._httpClient.patch(
      `${environment.backendUrl}/v1/${roleTypeObject}/${roleTypeObjectId}/remove_member`,
      memberdata,
      { withCredentials: true }
    );
  }

  //get project and users (dynamic webservice )
  getObjectAndUsers(query: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      query,
      { withCredentials: true }
    );
  }

  //get boards (dynamic webservice )
  getBoards(query: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      query,
      { withCredentials: true }
    );
  }

  //update object details
  //
  updateObjectDetails(
    objectRoleType: string,
    objectData: any,
    objectId: string
  ): Observable<any> {
    return this._httpClient.put(
      `${environment.backendUrl}/v1/${objectRoleType}/${objectId}`,
      objectData,
      { withCredentials: true }
    );
  }
  createIdentity(email: string): Observable<any> {
    const body = { email: email };
    return this._httpClient.post(
      `${environment.kratosUpdaterUrl}/v1/createUser`,
      body
    );
  }

  getIdentities(email: string): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.kratosUpdaterUrl}/v1/getIdentities`,
      {
        email: email,
      }
    );
  }

  getFilteredIdentity(email: string): Observable<any> {
    return this._httpClient.get<any>(
      `${environment.kratosUpdaterUrl}/v1/getFilteredIdentity/${email}`
    );
  }

  //add workshop
  createProject(projectData: any): Observable<any> {
    const Customerid = 'clivkh7ei0000tb2scg5tzq3z';
    return this._httpClient.post(
      `${environment.backendUrl}/v1/Customer/${Customerid}/belongs_to/project`,
      projectData,
      { withCredentials: true }
    );
  }

  //add activity
  createActivity(projectData: any, projectId: any): Observable<any> {
    return this._httpClient.post(
      `${environment.backendUrl}/v1/project/${projectId}/is_part_of/board`,
      projectData,
      { withCredentials: true }
    );
  }
  //
  // Get all workshops by user
  //
  getallWorkshops(): Observable<any> {
    return this._httpClient.get(
      `${environment.backendUrl}/v1/list/ofRecent/project`,
      { withCredentials: true }
    );
  }
  //
  // Get all workshops by user
  //
  accessWorkshop(id: any): Observable<any> {
    return this._httpClient.get<any>(
      `${environment.backendUrl}/v1/levels/project/` + id,
      { withCredentials: true }
    );
  }
  //
  // Get all workshops by user
  //
  getactivityColors(colorData: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      colorData,
      { withCredentials: true }
    );
  }

  //
  addTask(colorData: any, acitivityId: any): Observable<any> {
    return this._httpClient.post(
      `${environment.backendUrl}/v1/board/${acitivityId}/is_part_of/task`,
      colorData,
      { withCredentials: true }
    );
  }

  updateTask(projectData: any, projectId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.backendUrl}/v1/task/${projectId}`,
      projectData,
      { withCredentials: true }
    );
  }

  getSectionCards(colorData: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      colorData,
      { withCredentials: true }
    );
  }
  //
  //
  addactivityColor(colorData: any, acitivityId: any): Observable<any> {
    return this._httpClient.post(
      `${environment.backendUrl}/v1/board/${acitivityId}/is_part_of/label`,
      colorData,
      { withCredentials: true }
    );
  }

  //update workshop
  //
  updateBoard(projectData: any, projectId: string): Observable<any> {
    return this._httpClient.put(
      `${environment.backendUrl}/v1/board/${projectId}`,
      projectData,
      { withCredentials: true }
    );
  }

  getworkshopBoard(id: string): Observable<any> {
    return this._httpClient.get<any>(
      `${environment.backendUrl}/v1/levels/project/` + id,
      { withCredentials: true }
    );
  }

  getObjectMembers(roleTypeId: string): Observable<any> {
    return this._httpClient.get(
      `${environment.backendUrl}/v1/${roleTypeId}/access-relations`,
      { withCredentials: true }
    );
  }

  getObjectMembersByDirectRelation(roleTypeId: string): Observable<any> {
    return this._httpClient.get(
      `${environment.backendUrl}/v1/${roleTypeId}/access-direct-relations`,
      { withCredentials: true }
    );
  }

  // update member role
  updateMemberRole(memberdata: any): Observable<any> {
    return this._httpClient.patch(
      `${environment.backendUrl}/v1/update-level`,
      memberdata,
      { withCredentials: true }
    );
  }

  // update member role
  removeMemberRelation(memberdata: any): Observable<any> {
    return this._httpClient.post(
      `${environment.backendUrl}/v1/delete-access-relation`,
      memberdata,
      { withCredentials: true }
    );
  }

  getAllUsers = (): Observable<any[]> => {
    return this._httpClient.get<any[]>(`${environment.backendUrl}/v1/User`, {
      withCredentials: true,
    });
  };

  getUserGroupsByRoleTypeId = (roleTypeId: string): Observable<any[]> => {
    return this._httpClient.get<any[]>(
      `${environment.backendUrl}/v1/user-groups/${roleTypeId}`,
      {
        withCredentials: true,
      }
    );
  };

  inviteMultipeUsers = (body: {
    roleTypeId: string;
    roleTypeName: string;
    users: { id: string; email: string; redirectTo: string }[];
    role: string;
  }): Observable<any> => {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/add-multiple-users`,
      body,
      {
        withCredentials: true,
      }
    );
  };

  addMultipleUserGroups(body: {
    roleTypeId: string;
    roleTypeName: string;
    userGroups: any[];
    role: string;
    context: string;
    defaultRedirection: string;
  }): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/add-multiple-usergroups`,
      body,
      {
        withCredentials: true,
      }
    );
  }

  sendEmails(body: {
    userGroupsMembers: any[];
    roleTypeId: string;
  }): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/send-emails`,
      body,
      {
        withCredentials: true,
      }
    );
  }

  checkUserGroupsMemberRelations(body: {
    roleTypeId: string;
    roleTypeName: string;
    userGroups: any[];
  }): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/check-direct-relations`,
      body,
      {
        withCredentials: true,
      }
    );
  }

  getObjects(query: any): Observable<any> {
    return this._httpClient.post<any>(
      `${environment.backendUrl}/v1/dynamic/tree`,
      query,
      { withCredentials: true }
    );
  }
  async inviteUsers(
    invitedUsers: any[],
    roleTypeId: string,
    roleTypeName: string,
    context: string,
    role: string
  ): Promise<void> {
    const usersRequestData: {
      id: string;
      email: string;
      redirectTo: string;
    }[] = [];

    const userPromises = invitedUsers.map(async user => {
      try {
        const identityResponse = await this.createIdentity(
          user.email
        ).toPromise();

        if (identityResponse !== false) {
          const { identity } = identityResponse;
          this.pushUserRequestData(
            usersRequestData,
            user.email,
            identity.id,
            `${environment.completeRegistrationUrl}?email=${user.email}&id=${
              identity.id
            }&return_to=${encodeURIComponent(
              window.location.href
            )}&roletype=${roleTypeName}`
          );
        } else {
          // If identity creation failed, attempt to retrieve existing identity
          try {
            const res = await this.getIdentities(user.email).toPromise();
            if (res.exists) {
              this.pushUserRequestData(
                usersRequestData,
                user.email,
                res.id,
                `${environment.completeRegistrationUrl}?email=${
                  user.email
                }&id=${res.id}&return_to=${encodeURIComponent(
                  window.location.href
                )}&roletype=${roleTypeName}`
              );
            } else {
              this.pushUserRequestData(
                usersRequestData,
                user.email,
                user.id,
                window.location.href
              );
            }
          } catch (getError) {
            console.error('Error retrieving identity:', getError);
            this.toastr.error(
              `An error occurred while retrieving identity for ${user.email}.`
            );
          }
        }
      } catch (error) {
        console.error('Error creating identity:', error);
        this.toastr.error(
          `An error occurred while creating identity for ${user.email}.`
        );
      }
    });

    try {
      await Promise.all(userPromises);
      const body = {
        roleTypeId: roleTypeId,
        roleTypeName: roleTypeName,
        users: usersRequestData,
        role: role,
        context: context,
      };
      await this.inviteMultipeUsers(body).toPromise();

      // if (isUserGroup) {
      //   const body = {
      //     roleTypeId: roleTypeId,
      //     roleTypeName: roleTypeName,
      //     users: usersRequestData,
      //     role: role,
      //     context: context,
      //   };
      //   await this.inviteMultipeUsers(body).toPromise();
      // }

      this.toastr.success('Invitation sent successfully.');
    } catch (inviteError) {
      console.error('Error inviting multiple users:', inviteError);
      this.toastr.error(
        'An error occurred while sending invitations. Please try again.'
      );
    }
  }

  async inviteUsersToWorkspace(
    invitedUsers: { email: string; id?: string }[],
    workspaceId: string,
    role: string
  ): Promise<void> {
    const usersRequestData: {
      id: string;
      email: string;
      redirectTo: string;
    }[] = [];

    const userPromises = invitedUsers.map(async user => {
      try {
        const res = await this.createIdentity(user.email).toPromise();
        this.pushUserRequestData(
          usersRequestData,
          user.email,
          res.id,
          `${environment.completeRegistrationUrl}?email=${user.email}&id=${
            res.id
          }&return_to=${encodeURIComponent(
            window.location.href
          )}&roletype=workspace`
        );
      } catch (error) {
        try {
          const res = await this.getFilteredIdentity(user.email).toPromise();
          if (res.exists) {
            this.pushUserRequestData(
              usersRequestData,
              user.email,
              res.id,
              `${environment.completeRegistrationUrl}?email=${user.email}&id=${
                res.id
              }&return_to=${encodeURIComponent(
                window.location.href
              )}&roletype=workspace`
            );
          } else {
            this.pushUserRequestData(
              usersRequestData,
              user.email,
              res.id,
              environment.appBaseUrl
            );
          }
        } catch (getError) {
          console.error('Error retrieving identity:', getError);
          this.toastr.error(
            `An error occurred while retrieving identity for ${user.email}.`
          );
        }
      }
    });

    try {
      await Promise.all(userPromises);

      const body = {
        roleTypeId: workspaceId,
        roleTypeName: 'Workspace',
        users: usersRequestData,
        role: role,
        context: workspaceId,
      };

      await this.inviteMultipeUsers(body).toPromise();
      this.toastr.success('Invitation sent successfully.');
    } catch (inviteError) {
      console.error('Error inviting multiple users:', inviteError);
      this.toastr.error(
        'An error occurred while sending invitations. Please try again.'
      );
    }
  }

  private pushUserRequestData(
    usersRequestData: any[],
    email: string,
    id: string,
    redirectTo: string
  ): void {
    usersRequestData.push({
      id,
      email,
      redirectTo,
    });
  }

  private getOperationId(roleType: string): string | number {
    switch (roleType) {
      case 'Project':
        return 'PROJECT_MEMBER_INVITATION_OPERATION_ID';
      case 'Board':
        return 'BOARD_MEMBER_INVITATION_OPERATION_ID';
      case 'Workshop':
        return 'BOARD_MEMBER_INVITATION_OPERATION_ID';
      default:
        console.warn(`roleType not found : ${roleType}`);
        return -1;
    }
  }
  private getNotificationName(roleType: string): string | number {
    switch (roleType) {
      case 'Project':
        return 'inviteMemberToProjectNotification';
      case 'Board':
        return 'inviteMemberToBoardNotification';
      case 'Workshop':
        return 'inviteMemberToWorkshopNotification';
      default:
        console.warn(`roleType not found : ${roleType}`);
        return -1;
    }
  }
}
