import { Injectable } from '@angular/core';
import { MyNotificationsService } from '@excelway/services/notification.service';
import { SocketService } from '@excelway/services/socket-io.service';
import { AuthUser } from '@excelway/types/auth-user.types';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
//import { Notification } from 'app/layout/common/notifications/notifications.types';
import { AuthStoreSelectors } from 'app/store/auth';
import { Observable, switchMap, withLatestFrom } from 'rxjs';

interface Notification {
  id: string;
  name: string;
  createdAt: string;
  value: {
    type: string;
    notifiedUsers: { email: string; isRead: boolean }[];
    notificationCreator: {
      id: string;
      name?: string;
      value?: { email: string };
    };
    notificationSourceObject: { id: string; name: string };
  };
  operation: {
    id: string;
    name: string;
    value: { messageContent: { ar: string; en: string; fr: string } };
  }[];
  isRead: boolean;
}

interface UserNotification {
  notifiedUser: {
    id: string;
    name: string;
    email: string;
  };
  notifications: Notification[];
}

interface UserNotificationsState {
  userNotifications: UserNotification;
  connectedMembers: AuthUser[];
}

export const initialState: UserNotificationsState = {
  userNotifications: {
    notifiedUser: {
      id: '',
      name: '',
      email: '',
    },
    notifications: [],
  },
  connectedMembers: [],
};

@Injectable()
export class NotificationsStore extends ComponentStore<UserNotificationsState> {
  constructor(
    private readonly _store: Store,
    private readonly _socketService: SocketService,
    private readonly _notificationsService: MyNotificationsService
  ) {
    super(initialState);
  }

  ngrxOnStateInit(): void {
    this.joinRoom('user-notifications-room-id-1234567890');
    this.readEvents();
  }

  //*********Selector for notifications********* */
  readonly notificationsSelector = this.select(state => {
    if (
      state.userNotifications &&
      state.userNotifications.notifications.length > 0
    ) {
      return state.userNotifications.notifications;
    } else {
      return [];
    }
  });

  //***********Selector for notifiedUser************* */
  readonly notifiedUserSelector = this.select(state => {
    if (state.userNotifications && state.userNotifications.notifiedUser) {
      return state.userNotifications.notifiedUser;
    } else {
      return null;
    }
  });

  //**************************************************effects***************************************

  // Effect to get the list of notifications
  readonly getNotifications = this.effect((userId$: Observable<string>) =>
    userId$.pipe(
      switchMap(userId =>
        this._notificationsService
          .getListOfNotifications<{
            notifiedUser: { id: string; name: string; email: string };
            notifications: Notification[];
          }>(userId)
          .pipe(
            tapResponse(
              response => {
                this.setNotifications({
                  notifiedUser: response.notifiedUser,
                  notifications: response.notifications,
                });
              },
              error => console.error('Error fetching notifications:', error)
            )
          )
      )
    )
  );

  // Effect to create a new notification
  readonly createNotification = this.effect(
    (
      notificationDetails$: Observable<{
        notifiedUsers: {
          id?: string;
          name?: string;
          email: string;
          redirectTo?: string;
          isRead?: boolean;
        }[];
        operationId: string;
        name: string;
        type: string;
        notificationSourceObject: { id: string; roleTypeName: string };
      }>
    ) =>
      notificationDetails$.pipe(
        switchMap(details =>
          this._notificationsService
            .createNotification(
              details.notifiedUsers,
              details.operationId,
              details.name,
              details.type,
              details.notificationSourceObject
            )
            .pipe(
              tapResponse(
                response => {
                  if (response) {
                    this._socketService.publishEvent({
                      roomId: 'user-notifications-room-id-1234567890',
                      eventType: 'create-new-notification',
                      payload: response,
                    });
                  }
                },
                error => console.error(error)
              )
            )
        )
      )
  );

  readonly markAsRead = this.effect(
    (
      notificationDetails$: Observable<{
        value: { isRead: boolean };
        notificationsIds: { id: string }[];
      }>
    ) =>
      notificationDetails$.pipe(
        switchMap(details =>
          this._notificationsService
            .markAsRead(details.value, details.notificationsIds)
            .pipe(
              tapResponse(
                response => {
                  if (response && response.updatedNotifications) {
                    response.updatedNotifications.forEach(notif => {
                      this.toggleNotificationAsRead({
                        notificationId: notif.id,
                        isRead: details.value.isRead,
                      });
                    });
                  }
                },
                error => console.error(error)
              )
            )
        )
      )
  );

  readonly joinRoom = this.effect((roomId$: Observable<string>) => {
    return roomId$.pipe(
      withLatestFrom(
        // Logged user
        this._store.select(AuthStoreSelectors.selectLoggedUser)
      ),
      tapResponse(
        ([roomId, user]: [string, AuthUser]) => {
          if (roomId) this._socketService.joinRoom(roomId, user);
        },
        error => console.error(error)
      )
    );
  });

  readonly leaveRoom = this.effect<void>(source$ =>
    source$.pipe(
      withLatestFrom(
        // Room id
        this.select(state => state.userNotifications?.notifiedUser.id),
        // Logged user
        this._store.select(AuthStoreSelectors.selectLoggedUser)
      ),
      tapResponse(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, roomId, user]: [void, string, AuthUser]) => {
          if (roomId) this._socketService.leaveRoom(roomId, user);
        },
        error => console.error(error)
      )
    )
  );

  readonly readEvents = this.effect<void>(() =>
    this._socketService.getEvents().pipe(
      withLatestFrom(this._store.select(AuthStoreSelectors.selectLoggedUser)),
      tapResponse(
        ([event, user]: [any, AuthUser]) => {
          switch (event.eventType) {
            case 'create-new-notification': {
              console.log('user : ', user);
              console.log('event payload : ', event.payload.value);
              const loggedUser = user;
              console.log('loggedUser : ', loggedUser);
              const isNotifiedUser = event.payload.value.notifiedUsers?.some(
                user => {
                  console.log('user from notifiedUsers : ', user);
                  return user.id === loggedUser.id;
                }
              );
              console.log('isNotifiedUser : ', isNotifiedUser);
              if (isNotifiedUser) {
                this.addNotification(event.payload as any);
              }
              break;
            }

            case 'connected-users': {
              // Update connected user list
              this.patchState({
                connectedMembers: event.payload as AuthUser[],
              });
              break;
            }
            default: {
              console.warn(`Received unexpected event`);
              break;
            }
          }
        },
        error => console.error(error)
      )
    )
  );

  //**************************************************updaters***************************************

  //***************Updater to set the notifications************** */

  readonly setNotifications = this.updater(
    (
      state: UserNotificationsState,
      userNotifications: {
        notifiedUser: {
          id: string;
          name: string;
          email: string;
        };
        notifications: Notification[];
      }
    ): UserNotificationsState => ({
      ...state,
      userNotifications: {
        ...state.userNotifications,
        notifications: userNotifications.notifications,
        notifiedUser: userNotifications.notifiedUser,
      },
    })
  );

  //**** Updater to add a new notification ***************/

  readonly addNotification = this.updater(
    (state: UserNotificationsState, newNotification: any) => {
      // Check if there are no notifications initially
      if (!state.userNotifications.notifications.length) {
        return {
          ...state,
          userNotifications: {
            ...state.userNotifications,
            notifications: [newNotification],
          },
        };
      }

      // Add the new notification to the existing notifications
      return {
        ...state,
        userNotifications: {
          ...state.userNotifications,
          notifications: [
            ...state.userNotifications.notifications,
            newNotification,
          ],
        },
      };
    }
  );

  //*****************mark notification as Read*************************** */

  readonly toggleNotificationAsRead = this.updater(
    (
      state: UserNotificationsState,
      {
        notificationId,
        isRead,
      }: {
        notificationId: string;
        isRead: boolean;
      }
    ): UserNotificationsState => {
      return {
        ...state,
        userNotifications: {
          ...state.userNotifications,
          notifications: state.userNotifications.notifications.map(
            notification => {
              if (notification.id === notificationId) {
                return {
                  ...notification,
                  isRead: isRead,
                };
              }
              return notification;
            }
          ),
        },
      };
    }
  );
}
