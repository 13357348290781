import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { InterfaceStore } from '../user-interface.store';
import { CreateInterfaceComponent } from './create-interface/create-interface.component';

@Component({
  selector: 'app-interface-list',
  templateUrl: './interface-list.component.html',
})
export class InterfaceListComponent implements OnInit, OnDestroy {
  interfaces$ = this.interfaceStore.interfaces$;
  editForm: FormArray;
  skip = 0;
  take = 5;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private interfaceStore: InterfaceStore,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    this.editForm = this.fb.array([]);
  }

  ngOnInit(): void {
    this.loadInterfaces();
    this.subscriptions.add(
      this.interfaces$.subscribe(interfaces => {
        this.setInterfaces(interfaces);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.interfaceStore.resetState();
  }

  loadInterfaces(): void {
    this.interfaceStore.getInterfacesList({ skip: this.skip, take: this.take });
  }

  loadMoreInterfaces(): void {
    this.skip += this.take;
    this.loadInterfaces();
  }

  setInterfaces(interfaces: any[]): void {
    this.editForm.clear();
    interfaces.forEach(userInterface => {
      this.editForm.push(this.createInterfaceFormGroup(userInterface));
    });
  }
  createInterfaceFormGroup(userInterface: any): FormGroup {
    return this.fb.group({
      id: [userInterface?.id],
      name: [{ value: userInterface.name, disabled: true }],
      description: [{ value: userInterface.description, disabled: true }],
      path: [{ value: userInterface.value?.path, disabled: true }],
      appServiceName: [
        { value: userInterface.appservice?.name, disabled: true },
      ],
      isEditing: [false],
    });
  }

  openCreateInterfaceDialog(): void {
    const dialogRef = this.dialog.open(CreateInterfaceComponent, {
      maxWidth: '60vw',
      maxHeight: '80vh',
      height: '100%',
      width: '100%',
    });

    dialogRef.afterClosed().subscribe(newInterface => {
      if (newInterface) {
        this.interfaceStore.createUserInterface({ body: newInterface });
      }
    });
  }

  enableEdit(index: number): void {
    const formGroup = this.editForm.at(index) as FormGroup;
    formGroup.get('name')?.enable();
    formGroup.get('description')?.enable();
    formGroup.get('path')?.enable();
    formGroup.get('isEditing')?.setValue(true);
  }

  saveEdit(index: number): void {
    const formGroup = this.editForm.at(index) as FormGroup;
    if (formGroup.valid) {
      const updatedInterface = formGroup.getRawValue();
      const formattedInterface = {
        id: updatedInterface.id,
        name: updatedInterface.name,
        description: updatedInterface.description,
        value: {
          path: updatedInterface.path,
        },
      };
      this.interfaceStore.updateUserInterface({
        id: updatedInterface.id,
        changes: formattedInterface,
        appServiceName: formGroup.get('appServiceName')?.value,
      });
      this.cancelEdit(index);
    }
  }

  cancelEdit(index: number): void {
    const formGroup = this.editForm.at(index) as FormGroup;
    formGroup?.get('name')?.disable();
    formGroup.get('description')?.disable();
    formGroup.get('path')?.disable();
    formGroup.get('appServiceName')?.disable();
    formGroup.get('isEditing')?.setValue(false);
  }
}
