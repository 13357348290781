import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

export interface UserInterface {
  name: string;
  description: string;
  path: string;
  appServiceId: string;
}

export interface CreateUserInterfacePayload {
  interfaceName: string;
  interfaceDescription: string;
  value: {
    interfacePath: string;
    appServiceId: string;
  };
}
export interface createFieldPayload {
  interfaceId: string;
  body: any;
}

export interface updateInterfacePayload {
  interfaceId: string;
  body: any;
}

@Injectable({
  providedIn: 'root',
})
export class UserInterfaceService {
  constructor(private _httpClient: HttpClient) {}

  /**
   * Create userInterface
   */
  createUserInterface(payload: UserInterface): Observable<any> {
    const body = {
      name: payload.name,
      description: payload.description,
      value: {
        path: payload.path,
      },
    };
    return this._httpClient.post<any>(
      `${environment.backendUrl}/interface/${payload.appServiceId}`,
      body,
      { withCredentials: true }
    );
  }

  /**
   * Create  field
   */
  createInterfaceField(payload: any, interfaceId: string): Observable<any> {
    const body = {
      name: payload.name,
      description: payload.description,
      value: payload.value,
    };
    return this._httpClient.post<any>(
      `${environment.backendUrl}/interface/field/${interfaceId}`,
      body,
      { withCredentials: true }
    );
  }

  /**
   * update userInterface
   */
  updateUserInterface(id: string, changes: any): Observable<any> {
    return this._httpClient.put<any>(
      `${environment.backendUrl}/interface/update/${id}`,
      changes
    );
  }

  /**
   * Create userInterface
   */
  updateField(payload: any, fieldId: string): Observable<any> {
    return this._httpClient.put<any>(
      `${environment.backendUrl}/interface/update/field/${fieldId}`,
      payload,
      { withCredentials: true }
    );
  }

  /*********get app services modules */
  getAppServicesModules(): Observable<any> {
    return this._httpClient.get<any>(
      `${environment.backendUrl}/interface/appservices`,
      { withCredentials: true }
    );
  }

  /*********get all interfces */
  getUserInterfaces(skip: number, take: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('skip', skip.toString());
    params = params.append('take', take.toString());

    return this._httpClient.get<any>(
      `${environment.backendUrl}/interface/list`,
      {
        params: params,
        withCredentials: true,
      }
    );
  }

  /*********get all fields */
  getFields(interfaceId: string, skip: number, take: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('skip', skip.toString());
    params = params.append('take', take.toString());

    return this._httpClient.get<any>(
      `${environment.backendUrl}/interface/fields/${interfaceId}`,
      {
        params: params,
        withCredentials: true,
      }
    );
  }
}
